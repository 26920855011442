<template lang='pug'>
v-row.rowUpper
	v-col.py-0(v-if='areaSwitch', cols='12', md='6', lg='6')
		v-row.input-wrap.mx-0
			v-col.pa-0.labal-bgc.text-center(cols='3')
				span.caption {{ $t("common.cmsList") }}
			v-col.pa-0(cols='9')
				ValidationProvider(rules='required', v-slot='{ errors }')
					v-select.memberSelect.cars-select(
						v-model='area',
						:items='areaList',
						required,
						:error-messages='errors',
						single-line,
						background-color='rgba(255, 255, 255, 0.1)',
						dense,
						solo,
						item-color='#fff',
						:menu-props='menuProps',
						return-object
					)
						template(v-slot:selection='{ item, index }')
							span(v-if='selectItemName(area, areaList, index)')
								span {{ item }}
	v-col.py-0(v-if='domainSwitch', cols='12', md='6', lg='6')
		v-row.input-wrap.mx-0
			v-col.pa-0.labal-bgc.text-center(cols='3')
				span.caption {{ $t("charts.domain") }}
			v-col.pa-0(cols='9')
				ValidationProvider(rules='required', v-slot='{ errors }')
					v-select.memberSelect.cars-select(
						v-model='value',
						:items='domain',
						required,
						:error-messages='errors',
						single-line,
						background-color='rgba(255, 255, 255, 0.1)',
						dense,
						solo,
						item-color='#fff',
						:menu-props='menuProps'
					)
						template(v-slot:selection='{ item, index }')
							span.text-center(v-if='selectItemName(value, domain, index)')
								span {{ item }}
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

export default {
	components: {
		ValidationProvider,
	},
	props: ['currencyStatus'],
	data() {
		return {
			areaSwitch: true,
			domainSwitch: true,
			area: [],
			areaList: [],
			value: [],
			domain: [],
			turnOff: false,
			menuProps: {
				offsetY: true,
			},
		};
	},
	methods: {
		selectItemName(data, items, index) {
			return index === 0 && data;
		},
	},
	computed: {
		...mapGetters(['getDomain', 'getCurrency', 'getCurrencyList']),
	},
	watch: {
		value: function () {
			let domainArray = [];
			let domainValue = this.value;
			domainValue = domainValue.split('-');
			for(let i of this.domain) {
				let tmpJ = i.split('-');
				domainArray.push(tmpJ[0]);
			}
			// this.$emit('emitDomainValue', this.value, this.domain);
			this.$emit('emitDomainValue', domainValue[0], domainArray, this.area);
		},
		domain: function (value) {
			const arr = JSON.parse(JSON.stringify(value));
			if (arr.length === 0) {
				this.turnOff = true;
			} else {
				this.turnOff = false;
			}
		},
		area: function (value) {
			let domainValue = this.getDomain['OCMS'][value];
			let tmp = Object.keys(domainValue);
			this.domain = tmp;
			this.value = [];
			if (this.domain.length <= 1) {
				this.domainSwitch = false;
				this.value = this.domain[0];
			} else {
				this.domainSwitch = true;
			}
		},
	},
	created() {
		this.areaList = Object.keys(this.getDomain['OCMS']);
		this.areaList.sort();
		if (this.areaList.length <= 1) {
			this.areaSwitch = false;
			this.area = this.areaList[0];
		}
		this.$emit('emitDomainValue', this.value, this.domain, this.area);
	},
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"rowUpper"},[(_vm.areaSwitch)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-row',{staticClass:"input-wrap mx-0"},[_c('v-col',{staticClass:"pa-0 labal-bgc text-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("common.cmsList")))])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"memberSelect cars-select",attrs:{"items":_vm.areaList,"required":"","error-messages":errors,"single-line":"","background-color":"rgba(255, 255, 255, 0.1)","dense":"","solo":"","item-color":"#fff","menu-props":_vm.menuProps,"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.selectItemName(_vm.area, _vm.areaList, index))?_c('span',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e()]}}],null,true),model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})]}}],null,false,3246681360)})],1)],1)],1):_vm._e(),(_vm.domainSwitch)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-row',{staticClass:"input-wrap mx-0"},[_c('v-col',{staticClass:"pa-0 labal-bgc text-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("charts.domain")))])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"memberSelect cars-select",attrs:{"items":_vm.domain,"required":"","error-messages":errors,"single-line":"","background-color":"rgba(255, 255, 255, 0.1)","dense":"","solo":"","item-color":"#fff","menu-props":_vm.menuProps},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.selectItemName(_vm.value, _vm.domain, index))?_c('span',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e()]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})]}}],null,false,3197341673)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
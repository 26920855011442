<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			SingleSelectItem(@emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w(v-if='Object.keys(getExportResult).length !== 0')
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')
				v-row
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgDailyRegNum')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgDailyGameConver')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgDailyDPSTConver')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgDaily2TDPSTConver')
				v-row
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.loginConverRate')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.gameConverRate')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.dpstConverRate')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.dpstMoreThen2TConverRate')
				v-row
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.grandRetention')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.grr0To1d')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.grr2To7d')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.grr8To29d')
				v-row
				span(v-if='Object.keys(funnelOption1).length !== 0')
					v-col.chartWrapNoMargin 
						b {{ $t('charts.newUsersDailyConversion') }}
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(funnelOption1).length !== 0')
						v-chart.chartMoveDown(:option='funnelOption1', ref='funnelOption1', autoresize)
					ChartsSwitch(:firstOption='lineOption1' :secondOption='stackBarOption1')
				span(v-if='Object.keys(funnelOption2).length !== 0')
					v-col.chartWrapNoMargin 
						b {{ $t('charts.newUsersDailyRetention') }}
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(funnelOption2).length !== 0')
						v-chart.chartMoveDown(:option='funnelOption2', ref='funnelOption2', autoresize)
					ChartsSwitch(:firstOption='lineOption2' :secondOption='stackBarOption2')
				v-row
					v-col.chartWrap(cols='12', lg='12', xs='12', v-if='Object.keys(heatMapOption1).length !== 0')
						v-chart(v-if='timeGap < 20' :option='heatMapOption1', ref='heatMapOption1', autoresize)
						v-chart(v-else v-bind:style="{height: timeGap * 20 + 'px !important' }" :option='heatMapOption1', ref='heatMapOption1', autoresize)
</template>
<style scoped>
@media (max-width: 1500px) {
	.dataSelectBox {
		left: 0%;
	}
}
</style>
<script>
import { errorLogger } from '@/util/logger';
import { getSiteCvt } from '@/api/House';
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ChartsSwitch from '@/components/Charts/ChartsSwitch';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import ECharts from 'vue-echarts';
import ExportBtn from '@/components/ExportBtn';
import Kanban from '@/components/kanbans/Kanban';
import SingleSelectItem from '@/components/SingleSelectItem';
import dateType from '@/assets/constant/dateType';
import statusCode from '@/assets/constant/statusCode';
import statusMsg from '@/util/http';
import { userFunnelChart } from '@/assets/echarts/ocmsCommon/funnel';
import { bar, heatMap } from '@/assets/echarts/ocmsCommon/house/cvt';
import { barPercent, line } from '@/assets/echarts/ocmsCommon/house/reg';
import { exportXlsx } from '@/util/xlsx';
import timeIntervalType from '@/assets/constant/timeIntervalType';
import COLORS from '@/theme/color';
import {
	commaFormatter,
	nFormatter,
	trans,
	transData,
	transfer,
	toThousandslsFilter,
	rateOfExchange,
	roundOff,
	roundOffFilter,
	rdPercent,
	roundDecimal,
	roundFilter,
	getNaN,
} from '@/util/format';

export default {
	components: {
		'v-chart': ECharts,
		ChartsSwitch,
		DaliyPicker,
		ExportBtn,
		Kanban,
		SingleSelectItem,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			dialog: false,
			getExportResult: {},
			helperShow: false,
			show: false,
			tab: null,
			loading: false,
			percentSwitchTitle: this.$t('charts.showPercent'),
			dates: [],
			searchDates: [],
			dateType: dateType.BRIEF_DATE,
			timeGap: 0,
			imgSrc: require('@/assets/image/research.png'),
			cardData: {},
			groupName: timeIntervalType['afwd'],
			groupKey: Object.keys(timeIntervalType['afwd']),
			groupValue: Object.values(timeIntervalType['afwd']),
			groupColor: [
				COLORS.dark_water_blue_green,
				COLORS.water_blue_green,
				COLORS.light_green,
				COLORS.mustard,
				COLORS.wisteria[4],
			],
			funnelOption1: {},
			funnelOption2: {},
			lineOption1: {},
			lineOption2: {},
			stackBarOption1: {},
			stackBarOption2: {},
			heatMapOption1: {},
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				const reqData = {
					startDate: this.$moment(this.dates[0]).format(dateType.DATE),
					endDate: this.$moment(this.dates[1]).format(dateType.DATE),
					domain: [this.value],
				};
				this.searchDates = [reqData.startDate, reqData.endDate];
				this.timeGap = this.$moment(this.searchDates[1]).diff(this.searchDates[0], 'days') + 1;
				const res = await getSiteCvt(reqData);
				if (res.status === statusCode.STATUS_OK) {
					const result = JSON.parse(JSON.stringify(res.result.res));
					// console.log('result', result);
					this.cvtOrg = result.ctx_cvt_org;
					const earlyCvtAll = result.ctx_cvt_all_early[0];
					const earlyActSum = result.ctx_act_sum_early;
					const cvtAll = result.ctx_cvt_all[0];
					this.actAll = result.ctx_act_all[0];
					const actSum = result.ctx_act_sum;
					this.actOrg = result.ctx_act_org;
					const actEach = result.ctx_kpi_ret[0].ctx_act_each;
					this.earlyGrandRet = result.ctx_kpi_ret_early[0].grand_retention;
					this.grandRet = result.ctx_kpi_ret[0].grand_retention;
					this.convertFunnelChart(cvtAll);
					this.convertLineChart(this.cvtOrg);
					this.convertBarChart(this.cvtOrg);
					const earlyActRe = await this.retentionFunnelChart(earlyActSum);
					const actRe = await this.retentionFunnelChart(actSum);
					this.retentionBarChart(this.actOrg);
					this.retentionLineChart(this.actOrg);
					this.retentionHeatMapChart(actEach);
					this.cardDisplay(earlyCvtAll, earlyActRe, cvtAll, actRe);
					this.getExportResult = JSON.parse(JSON.stringify(result));
				}
			} catch (e) {
				errorLogger(e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async cardDisplay(earlyCvtAll, earlyActRe, cvtAll, actRe) {
			this.cardData = {
				avgDailyRegNum: {
					mainTitle: this.$t('charts.avgDailyRegNum'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: cvtAll.avg_reg_users || 0,
					subData: earlyCvtAll.avg_reg_users || 0,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalRegisterUsers'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: cvtAll.ttl_reg_users || 0,
					toolSubData: earlyCvtAll.ttl_reg_users || 0,
					note: 'people'
				},
				avgDailyGameConver: {
					mainTitle: this.$t('charts.avgDailyGameConver'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: cvtAll.avg_gm_users || 0,
					subData: earlyCvtAll.avg_gm_users || 0,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalGamingUsersCount'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: cvtAll.ttl_gm_users || 0,
					toolSubData: earlyCvtAll.ttl_gm_users || 0,
					note: 'people'
				},
				avgDailyDPSTConver: {
					mainTitle: this.$t('charts.avgDailyDPSTConver'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: cvtAll.avg_dpst_01_users || 0,
					subData: earlyCvtAll.avg_dpst_01_users || 0,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalDpstConvertUsers'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: cvtAll.ttl_dpst_01_users || 0,
					toolSubData: earlyCvtAll.ttl_dpst_01_users || 0,
					note: 'people'
				},
				avgDaily2TDPSTConver: {
					mainTitle: this.$t('charts.avgDaily2TDPSTConver'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: cvtAll.avg_dpst_02_users || 0,
					subData: earlyCvtAll.avg_dpst_02_users || 0,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalTwoDpstConvertUsers'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: cvtAll.ttl_dpst_02_users || 0,
					toolSubData: earlyCvtAll.ttl_dpst_02_users || 0,
					note: 'people'
				},
				loginConverRate: {
					mainTitle: this.$t('charts.loginConverRate'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: cvtAll.ttl_login_users / cvtAll.ttl_reg_users || 0,
					subData: earlyCvtAll.ttl_login_users / earlyCvtAll.ttl_reg_users || 0,
					note: 'percent'
				},
				gameConverRate: {
					mainTitle: this.$t('charts.gameConverRate'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: cvtAll.ttl_gm_users / cvtAll.ttl_reg_users || 0,
					subData: earlyCvtAll.ttl_gm_users / earlyCvtAll.ttl_reg_users || 0,
					note: 'percent'
				},
				dpstConverRate: {
					mainTitle: this.$t('charts.dpstConverRate'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: cvtAll.ttl_dpst_01_users  / cvtAll.ttl_reg_users || 0,
					subData: earlyCvtAll.ttl_dpst_01_users / earlyCvtAll.ttl_reg_users || 0,
					note: 'percent'
				},
				dpstMoreThen2TConverRate: {
					mainTitle: this.$t('charts.dpstMoreThen2TConverRate'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: cvtAll.ttl_dpst_02_users / cvtAll.ttl_reg_users || 0,
					subData: earlyCvtAll.ttl_dpst_02_users / earlyCvtAll.ttl_reg_users || 0,
					note: 'percent'
				},
				grandRetention: {
					mainTitle: this.$t('charts.grandRetention'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: this.grandRet || 0,
					subData: this.earlyGrandRet || 0,
					note: 'percent'
				},
				grr0To1d: {
					mainTitle: this.$t('charts.grr0To1d'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: actRe['0~1'],
					subData: earlyActRe['0~1'] || 0,
					note: 'percent'
				},
				grr2To7d: {
					mainTitle: this.$t('charts.grr2To7d'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: actRe['2~7'],
					subData: earlyActRe['2~7'] || 0,
					note: 'percent'
				},
				grr8To29d: {
					mainTitle: this.$t('charts.grr8To29d'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: actRe['8~29'],
					subData: earlyActRe['8~29'] || 0,
					note: 'percent'
				},
			}
		},
		async convertFunnelChart(res) {
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			this.funnelTmpData = {
				title: '',
				// title: this.$t('charts.newUsersConversion'),
				// subtext: this.$t('charts.avdDailyUsers'),
				legend: legendData,
				name: this.$t('charts.newUsersConversion'),
				data: {
					[this.$t('charts.regUsers')]: res.avg_reg_users,
					[this.$t('charts.loginUsers')]: res.avg_login_users,
					[this.$t('charts.gamingUsers')]: res.avg_gm_users,
					[this.$t('charts.dpstUsers')]: res.avg_dpst_01_users,
					[this.$t('charts.secondDpst')]: res.avg_dpst_02_users,
				},
				subTitle: [
					this.$t('charts.avgRegUsers'),
					this.$t('charts.avgLoginUsers'),
					this.$t('charts.avgGamingUsers'),
					this.$t('charts.avgDpstUsers'),
					this.$t('charts.avgSecondDpst')
				],
				subData: [
					res.ttl_reg_users,
					res.ttl_login_users,
					res.ttl_gm_users,
					res.ttl_dpst_01_users,
					res.ttl_dpst_02_users,
				]
			};
			this.funnelOption1 = await userFunnelChart(this.funnelTmpData);
		},
		async convertLineChart(res) {
			const regUsers = [],
				loginUsers = [],
				gamingUsers = [],
				dpstUsers = [],
				secDpstUsers = [],
				xAxisData = [];
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					regUsers.push(i.ttl_reg_users);
					loginUsers.push(i.ttl_login_users);
					gamingUsers.push(i.ttl_gm_users);
					dpstUsers.push(i.ttl_dpst_01_users);
					secDpstUsers.push(i.ttl_dpst_02_users);
				}
				this.lineOption1 = await line({
					// title: this.$t('charts.newUsersDailyConversion'),
					legend: legendData,
					name: '',
					xAxis: xAxisData,
					series: [
						{
							name: this.$t('charts.regUsers'),
							type: 'line',
							color: COLORS.dark_water_blue_green,
							data: regUsers,
						},
						{
							name: this.$t('charts.loginUsers'),
							type: 'line',
							color: COLORS.water_blue_green,
							data: loginUsers,
						},
						{
							name: this.$t('charts.gamingUsers'),
							type: 'line',
							color: COLORS.light_green,
							data: gamingUsers,
						},
						{
							name: this.$t('charts.dpstUsers'),
							type: 'line',
							color: COLORS.mustard,
							data: dpstUsers,
						},
						{
							name: this.$t('charts.secondDpst'),
							type: 'line',
							color: COLORS.wisteria[4],
							data: secDpstUsers,
						},
					],
				});
				this.lineOption1.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${toThousandslsFilter(i.value)}<br/>`;
					}
					return str;
				};
			}
		},
		async retentionLineChart(res) {
			const tmpData = [], xAxisData = [];
			const tmp = {
				'cvt_gm': [],
				'0~1': [],
				'2~7': [],
				'8~29': [],
			};
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					for (let j of this.groupKey) {
						if (_.toString(i.tag_reg_term) === j) {
							tmp[i.tag_reg_term].push(i);
						}
					}
				}
				for (let i in tmp) {
					let usersArr = [];
					let obj1 = {
						name: this.groupName[i],
						type: 'line',
						data: [],
					};
					for (let j of tmp[i]) {
						usersArr.push(j.avg_gm_users_real);
					}
					obj1.data = usersArr;
					tmpData.push(obj1);
				}
				this.lineOption2 = await line({
					color: [COLORS.orange_red, '#32C5E9', '#FFDB5C', '#A569BD'],
					// title: this.$t('charts.newUsersDailyRetention'),
					legend: this.groupValue,
					name: '',
					xAxis: Array.from(new Set(xAxisData)),
					series: tmpData,
				});
				this.lineOption2.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				};
			}
		},
		async convertBarChart(res) {
			const data = [],
				dataMap = [],
				toolTipMap = [],
				xAxisData = [],
				regUsers = [],
				loginUsers = [],
				gamingUsers = [],
				dpstUsers = [],
				secDpstUsers = [];
			const regArr = [], loginArr = [], gamingArr = [], dpstArr = [], secDpstArr = [];
			const regPer = [], loginPer = [], gamingPer = [], dpstPer = [], secDpstPer = [];
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					regUsers.push(i.ttl_reg_users);
					loginUsers.push(i.ttl_login_users);
					gamingUsers.push(i.ttl_gm_users);
					dpstUsers.push(i.ttl_dpst_01_users);
					secDpstUsers.push(i.ttl_dpst_02_users);
					regPer.push(roundDecimal(i.ttl_reg_users / i.ttl_reg_users * 100));
					loginPer.push(roundDecimal(i.ttl_login_users / i.ttl_reg_users * 100));
					gamingPer.push(roundDecimal(i.ttl_gm_users / i.ttl_reg_users * 100));
					dpstPer.push(roundDecimal(i.ttl_dpst_01_users / i.ttl_reg_users * 100));
					secDpstPer.push(roundDecimal(i.ttl_dpst_02_users / i.ttl_reg_users * 100));
					regArr.push((i.ttl_reg_users - i.ttl_login_users) / i.ttl_reg_users * 100);
					loginArr.push((i.ttl_login_users - i.ttl_gm_users) / i.ttl_reg_users * 100);
					gamingArr.push((i.ttl_gm_users - i.ttl_dpst_01_users) / i.ttl_reg_users * 100);
					dpstArr.push((i.ttl_dpst_01_users - i.ttl_dpst_02_users) / i.ttl_reg_users * 100);
					secDpstArr.push(i.ttl_dpst_02_users / i.ttl_reg_users * 100);
				}
				dataMap.push(secDpstUsers, dpstUsers, gamingUsers, loginUsers, regUsers);
				toolTipMap.push(secDpstPer, dpstPer, gamingPer, loginPer, regPer);
				data.push(
					{
						name: this.$t('charts.secondDpst'),
						type: 'bar',
						stack: 'all',
						color: '#A569BD',
						data: secDpstArr,
					},
					{
						name: this.$t('charts.dpstUsers'),
						type: 'bar',
						stack: 'all',
						color: '#FFDB5C',
						data: dpstArr,
					},
					{
						name: this.$t('charts.gamingUsers'),
						type: 'bar',
						stack: 'all',
						color: '#9FE6B8',
						data: gamingArr,
					},
					{
						name: this.$t('charts.loginUsers'),
						type: 'bar',
						stack: 'all',
						color: '#67E0E3',
						data: loginArr,
					},
					{
						name: this.$t('charts.regUsers'),
						type: 'bar',
						stack: 'all',
						color: '#32C5E9',
						data: regArr,
					},
				);
				this.barTmpData = {
					title: '',
					legend: legendData,
					name: this.$t('charts.newUsersDailyConversion'),
					xAxis: xAxisData,
					series: data,
				};
			}
			this.stackBarOption1 = await barPercent(this.barTmpData);
			// this.stackBarOption1.yAxis[0].min = -1;
			// this.stackBarOption1.yAxis[0].max = 102;
			this.stackBarOption1.tooltip.formatter = (params) => {
				let te = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
				params.reverse();
				for(let i of params) {
					te += `${i.marker}${i.seriesName}: ${roundOffFilter(dataMap[i.componentIndex][i.dataIndex])} (${toolTipMap[i.componentIndex][i.dataIndex]}%)<br/>`;
				}
				return te;
			}
		},
		async retentionFunnelChart(res) {
			const tmpGmUsers = {};
			const tmpPercent = [];
			const key = Object.keys(timeIntervalType['afwd']);
			const legendData = Object.values(timeIntervalType['afwd']);
			// legendData.unshift('all');
			const tmp = {
				color: [COLORS.orange_red, '#32C5E9', '#FFDB5C', '#A569BD'],
				title: '',
				// title: this.$t('charts.newUsersRetention'),
				// subtext: this.$t('member.avgDauNDAR'),
				legend: legendData,
				name: this.$t('charts.newUsersRetention'),
				data: {
					// 'all': roundDecimal(this.actAll.gm_cvt_users / this.actAll.data_term),
					// 'all': this.getExportResult.ctx_site_cvt_all.avg_gm_users
				},
			};
			if (res) {
				res.sort((a, b) => (parseInt(a.tag_reg_term) > parseInt(b.tag_reg_term) ? 1 : -1));
				for (let j of key) {
					for (let i = 0; i < res.length; i += 1) {
						if (j === res[i].tag_reg_term) {
							// tag_reg_term
							// tmp.data[j] = roundDecimal(res[i].gm_users / res[i].data_term); // gm_users / data_term
							// tmp.data[j] = res[i].gm_cvt_users;
							tmp.data[j] = res[i].avg_gm_users_real;
							tmpGmUsers[j] = res[i].retention;
							tmpPercent.push(res[i].retention);
						}
					}
				}
				const userValue = Object.values(tmp.data);
				this.funnelOption2 = userFunnelChart(tmp);
				this.funnelOption2.tooltip.formatter = (params) => {
					return `${params.marker}${params.name}: ${roundOffFilter(userValue[params.dataIndex])} (${rdPercent(
						tmpPercent[params.dataIndex]
					)})`;
				};
				this.funnelOption2.series[0].label.formatter = (params) => {
					return `${params.name}\n${roundOffFilter(userValue[params.dataIndex])}\n${rdPercent(
						tmpPercent[params.dataIndex]
					)}`;
				};
				return tmpGmUsers;
			}
		},
		async retentionBarChart(res) {
			const data = [];
			let xAxisData = [];
			const per = {}, percent = {}, toolTipData = {}, tmp = {
				'cvt_gm': [],
				'0~1': [],
				'2~7': [],
				'8~29': [],
			};
			const legendData = Object.values(timeIntervalType['afwd']);
			legendData.unshift(this.$t('charts.gameConver'));
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					tmp[i.tag_reg_term].push(i.avg_gm_users_real);
					percent[i.tag_reg_term] = [];
					per[i.dim_ptt] = [];
					toolTipData[i.dim_ptt] = [];
				}
				xAxisData = Array.from(new Set(xAxisData));
				res.sort((a, b) => (a.retention < b.retention ? 1 : -1));
				for(let i = 0; i < xAxisData.length; i += 1) {
					for(let j of res) {
						if(xAxisData[i] === j.dim_ptt) {
							per[xAxisData[i]].push(j);
							toolTipData[xAxisData[i]].push(j.retention);
						}
					}
				}
				for (let i in per) {
					for(let j = 0; j < per[i].length - 1; j += 1) {
						percent[per[i][j].tag_reg_term].push((per[i][j].retention - per[i][j+1].retention) * 100);
						if(j === per[i].length - 2) {
							percent[per[i][j+1].tag_reg_term].push(per[i][j+1].retention * 100);
						}
					}
				}
				data.push(
					{
						name: timeIntervalType['afwd']['8~29'],
						type: 'bar',
						stack: 'total',
						color: '#A569BD',
						data: percent['8~29'],
					},
					{
						name: timeIntervalType['afwd']['2~7'],
						type: 'bar',
						stack: 'total',
						color: '#FFDB5C',
						data: percent['2~7'],
					},
					{
						name: timeIntervalType['afwd']['0~1'],
						type: 'bar',
						stack: 'total',
						color: '#32C5E9',
						data: percent['0~1'],
					},
					{
						name: timeIntervalType['afwd']['cvt_gm'],
						type: 'bar',
						stack: 'total',
						color: COLORS.orange_red,
						data: percent['cvt_gm'],
					},
				);
				this.barTmpData = {
					title: '',
					legend: legendData,
					name: this.$t('charts.newUsersDailyRetention'),
					xAxis: xAxisData,
					series: data,
				};
				this.stackBarOption2 = await barPercent(this.barTmpData);
				this.stackBarOption2.yAxis[0].min = 0;
				this.stackBarOption2.yAxis[0].max = 100;
				this.stackBarOption2.legend.selectedMode = false;
				this.stackBarOption2.tooltip.formatter = (params) => {
					let te = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					params.reverse();
					for(let i = 0; i < params.length; i += 1) {
						let re = toolTipData[params[i].axisValue][i];
						te += `${params[i].marker}${params[i].seriesName}: ${roundDecimal(re * 100)}%<br/>`;
					}
					return te;
				}
			}
		},
		async retentionHeatMapChart(res) {
			const days = [], dates = [], data = [], userData = [];
			const tmpObj = {};
			const time = this.$moment(this.searchDates[1]).diff(this.searchDates[0], 'days') + 1;
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for(let i = 0; i < 30; i += 1) {
					days.push(i);
				}
				for(let i = 0; i < time; i += 1) {
					dates.push(this.$moment(this.searchDates[0]).add(i, 'day').format('YYYY-MM-DD').toString());
				}
				dates.reverse();
				for (let i of dates) {
					tmpObj[i] = [];
					for(let j of res) {
						if(j.dim_ptt === i) {
							tmpObj[i].push(j);
						}
					}
					tmpObj[i].sort((a, b) => (a.tag_reg_term > b.tag_reg_term ? 1 : -1));
				}
				for(let i in tmpObj) {
					let obj2 = [];
					for(let j of tmpObj[i]) {
						let obj = [];
						j.ret = roundDecimal(j.retention * 100);
						obj.push(j.tag_reg_term, dates.indexOf(i), j.ret);
						obj2.push(j.ttl_gm_users_real);
						data.push(obj);
					}
					userData.push(obj2);
				}
				this.heatMapOption1 = await heatMap({
					title: this.$t('charts.dailyGamingRetention'),
					color: JSON.parse(JSON.stringify(COLORS.green_sea)).reverse(),
					name: '',
					xAxis: days,
					yAxis: dates,
					max: 100,
					series: data,
				});
				this.heatMapOption1.tooltip.formatter = (params) => {
					let tmp = 0;
					for(let i of res) {
						if(i.dim_ptt === dates[params.data[1]] && i.tag_reg_term === params.data[0]) {
							tmp = i.ttl_gm_users_real;
						}
					}
					return ` ${params.marker} ${this.$moment(dates[params.data[1]]).format(dateType.BRIEF_DATE)}: ${days[params.data[0]]} ${this.$t('charts.day')}<br />
					${this.$t('charts.todayUsers')}: ${tmp} (${params.data[2]}%)`;
				}
			}
		},
		allClean() {
			this.cardData = {};
			this.funnelOption1 = {};
			this.funnelOption2 = {};
			this.lineOption1 = {};
			this.lineOption2 = {};
			this.stackBarOption1 = {};
			this.stackBarOption2 = {};
			this.heatMapOption1 = {};
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				// result.ctx_site_cvt_all = [result.ctx_site_cvt_all];
				// result.ctx_site_cvt_early_all = [result.ctx_site_cvt_early_all];
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		updateDomainValue(val, val2) {
			this.value = val;
			this.domainMap = val2;
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		closeDialog() {
			this.dialog = false;
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] === 'daily') {
					this.tab = 0;
				}
			}
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		updateDate(val) {
			this.dates = val;
		},
	},
	watch: {
		tab: function () {
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let div = $('.div-w');
					if (div.length) {
						let adscrtop = div.offset().top || { top: NaN }.top;
						if (scrollVal + 44 > adscrtop) {
							$('.fixBar').addClass('dateFixBar');
						} else {
							$('.fixBar').removeClass('dateFixBar');
						}
					}
				});
			});
		},
	},
	computed: {
		...mapGetters(['getDomain', 'getDialogShow', 'getStatusDialog', 'getOperatorDefaultRouter', 'getOpenAutoSearch']),
	},
	created() {},
};
</script>